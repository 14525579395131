<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2607_78188)">
      <path d="M7.56396 5.46191V7.96191M7.56396 10.4619H7.57021M13.814 7.96191C13.814 11.4137 11.0157 14.2119 7.56396 14.2119C4.11219 14.2119 1.31396 11.4137 1.31396 7.96191C1.31396 4.51013 4.11219 1.71191 7.56396 1.71191C11.0157 1.71191 13.814 4.51013 13.814 7.96191Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_2607_78188">
        <rect width="15" height="15" fill="white" transform="translate(0.0639648 0.461914)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconInfoCircle'
}
</script>
